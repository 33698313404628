<template>
  <td
    v-if="renderCell"
    class="pt-0 pb-0"
    :class="cellBackgroundClass"
  >
    <div
      :id="`${asupId}_popover_alerts`"
    >
      {{ currentAlertCount }}
      <b-popover
        :target="`${asupId}_popover_alerts`"
        triggers="hover"
        @show="onShow"
      >
        <template #title>
          Alert overview
        </template>

        <b-overlay :show="isLoading">
          <p v-if="isLoading">
            Loading...
          </p>

          <div v-if="!isLoading">
            <div v-if="lastAlerts.length > 0">
              <h5><u>Last 5 alerts</u></h5>
              <ul
                style="list-style: none; padding-left: 0;"
              >
                <li
                  v-for="alert in lastAlerts"
                  :key="alert.monitorId"
                  class="mb-1"
                >
                  {{ alert.dateTime | formatDateTime }}: {{ alert.message }}
                </li>
              </ul>
            </div>
          </div>
        </b-overlay>
      </b-popover>
    </div>
  </td>
</template>

<script>

import { BPopover, BOverlay } from 'bootstrap-vue'
import UiAttributeCellMixin from '@/components/grid/cell/UiAttributeCellMixin'

import NetWorkerService from '@/service/networker.service'

export default {
  components: {
    BPopover,
    BOverlay,
  },
  mixins: [
    UiAttributeCellMixin,
  ],
  props: {
    field: {
      type: String,
      required: true,
    },
    rowType: {
      type: String,
      required: true,
    },
    asupId: {
      type: String,
      required: true,
    },
    currentAlertCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isLoading: false,
      dataLoaded: false,
      asup: {},
    }
  },
  computed: {
    renderCell() {
      return this.field !== undefined && this.rowType !== 'groupHeader'
    },
    lastAlerts() {
      if (this.asup.details) {
        const result = this.asup.details.alerts
          .concat() // make copy to prevent overwriting real array
          .sort((a, b) => (new Date(a.dateTime) > new Date(b.dateTime) ? -1 : 1)) // sort by postTime

        return result.slice(0, 5) // return last 5 alerts
      }
      return []
    },
  },
  watch: {
    asupId() {
      /// cell stays the same after reordering grid. So we need to empty alerts cuz it could hold information about the data inside the same cell before reordering
      this.asup = {}
      this.dataLoaded = false // force reloading data for next time
    },
  },
  methods: {
    onShow() {
      if (this.dataLoaded === false) {
        this.isLoading = true

        NetWorkerService.getAsync(this.asupId, { disableTenantFilter: true })
          .then(result => {
            this.asup = result
          })
          .finally(() => {
            this.isLoading = false
            this.dataLoaded = true
          })
      }
    },
  },
}
</script>
